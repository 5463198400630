<template>
  <div id="superset-container" />
</template>
<style>
   #superset-container, iframe{
      width: 100%;
      height: calc(100vh - 67px);
   }

</style>
<script>
import { embedDashboard } from '@superset-ui/embedded-sdk';

import Config from '@/constants/supersetPaths';
import apiClient from '@/services/api-client';

export default {
  components: {
    embedDashboard,
  },
};
const urlParams = new URLSearchParams(window.location.search);
const targetName = urlParams.get('targetName');

async function fetchGuestTokenFromBackend() {
  const authStr = `Bearer ${apiClient.accessToken}`;
  const response = await fetch(`${apiClient.getBaseUrl()}${Config.URL_SUPERSET_LOGIN}?targetName=${targetName}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authStr,
    },
  });
  const data = await response.json();

  if (data.error_code !== 0) {
    alert(data.error_msg);

    return 0;
  }

  return data;
}

async function loadFrame() {
  const data = await fetchGuestTokenFromBackend();

  embedDashboard({
    id: data.DashboardId,
    supersetDomain: Config.SUPERSET_DOMAIN,
    mountPoint: document.getElementById('superset-container'),
    fetchGuestToken: () => data.token,
    dashboardUiConfig: {
      hideTitle: true,
    },
  });
}
loadFrame();
</script>
